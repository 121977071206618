const techs = [
  {
    name: 'JavaScript',
    image: 'javascript.png',
    hint: 'Programming language that is one of the core technologies of the World Wide Web.',
    url: 'https://en.wikipedia.org/wiki/JavaScript',
  },
  {
    name: 'Python',
    image: 'python.png',
    hint: 'Programming language that lets you work quickly and integrate systems more effectively.',
    url: 'https://www.python.org',
  },
  {
    name: 'React',
    image: 'react.png',
    hint: 'Library for building user interfaces.',
    url: 'https://reactjs.org',
  },
  {
    name: 'Relay',
    image: 'relay.png',
    hint: 'The GraphQL client that scales with you.',
    url: 'https://relay.dev/',
  },
  {
    name: '.Net',
    image: 'dot-net.png',
    hint: 'Platform with tools and libraries for building any type of app, including web, mobile, desktop, games, IoT, cloud, and microservices.',
    url: 'https://dotnet.microsoft.com/',
  },
  {
    name: 'Kubernetes',
    image: 'kubernetes.png',
    hint: 'System for automating deployment, scaling, and management of containerized applications.',
    url: 'https://kubernetes.io/',
  },
  {
    name: 'Docker',
    image: 'docker.png',
    hint: 'Makes development efficient and predictable.',
    url: 'https://www.docker.com/',
  },
  {
    name: 'Java',
    image: 'java.png',
    hint: 'Programming language that is designed to have as few implementation dependencies as possible.',
    url: 'https://www.java.com/',
  },
  {
    name: 'Laravel',
    image: 'laravel.png',
    hint: 'Web application framework with expressive, elegant syntax.',
    url: 'https://laravel.com/',
  },
  {
    name: 'Scala',
    image: 'scala.png',
    hint: 'Combines object-oriented and functional programming in one concise, high-level language.',
    url: 'https://www.scala-lang.org/',
  },
  {
    name: 'Spring',
    image: 'spring.png',
    hint: 'Application framework and inversion of control container for the platform.',
    url: 'https://spring.io/',
  },
  {
    name: 'Swift',
    image: 'swift.png',
    hint: 'Programming language that\'s as expressive and enjoyable as a scripting language.',
    url: 'https://www.swift.org/',
  },
  {
    name: 'Go',
    image: 'golang.png',
    hint: 'Go is a statically typed, compiled programming language designed at Google by Robert Griesemer, Rob Pike, and Ken Thompson.',
    url: 'https://go.dev/',
  },
  {
    name: 'Vue',
    image: 'vue.png',
    hint: 'An approachable, performant and versatile framework for building web user interfaces.',
    url: 'https://vuejs.org/',
  },
  {
    name: 'Docusaurus',
    image: 'docusaurus.png',
    hint: 'An optimized site generator in React.',
    url: 'https://docusaurus.io/',
  },
  {
    name: 'GraphQL',
    image: 'graphql.png',
    hint: 'Provides a complete and understandable description of the data in your API, gives clients the power to ask for exactly what they need and nothing more, makes it easier to evolve APIs over time, and enables powerful developer tools.',
    url: 'https://graphql.org/',
  },
  {
    name: 'Git',
    image: 'git.png',
    hint: 'Distributed version control system designed to handle everything from small to very large projects with speed and efficiency.',
    url: 'https://git-scm.com/',
  },
  {
    name: 'Kotlin',
    image: 'kotlin.png',
    hint: 'A modern programming language that makes developers happier.',
    url: 'https://kotlinlang.org/',
  },
  {
    name: 'Jest',
    image: 'jest.png',
    hint: 'Delightful JavaScript Testing Framework with a focus on simplicity.',
    url: 'https://jestjs.io/',
  },
  {
    name: 'Rust',
    image: 'rust.png',
    hint: 'A language empowering everyone to build reliable and efficient software.',
    url: 'https://www.rust-lang.org/',
  },
  {
    name: 'Ruby',
    image: 'ruby.png',
    hint: 'Language with a focus on simplicity and productivity. It has an elegant syntax that is natural to read and easy to write.',
    url: 'https://www.ruby-lang.org/',
  },
  {
    name: 'Jenkins',
    image: 'jenkins.png',
    hint: 'Automation server which enables developers around the world to reliably build, test, and deploy their software.',
    url: 'https://www.jenkins.io/',
  },
  {
    name: 'ESLint',
    image: 'eslint.png',
    hint: 'Find and fix problems in your JavaScript code.',
    url: 'https://eslint.org/',
  },
  {
    name: 'Postman',
    image: 'postman.png',
    hint: 'API client that makes it easy for developers to create, share, test and document APIs.',
    url: 'https://www.postman.com/',
  },
  {
    name: 'Angular',
    image: 'angular.png',
    hint: 'Platform for building mobile and desktop web applications.',
    url: 'https://angular.io/',
  },
  {
    name: 'Hibernate',
    image: 'hibernate.png',
    hint: 'Reactive domain model persistence for relational databases.',
    url: 'https://hibernate.org/',
  },
  {
    name: 'Gradle',
    image: 'gradle.png',
    hint: 'From mobile apps to microservices, from small startups to big enterprises, it helps teams build, automate and deliver better software, faster.',
    url: 'https://gradle.org/',
  },
  {
    name: 'Swagger',
    image: 'swagger.png',
    hint: 'Helps users build, document, test and consume RESTful web services.',
    url: 'https://swagger.io/',
  },
  {
    name: 'Dart',
    image: 'dart.png',
    hint: 'Client-optimized language for fast apps on any platform.',
    url: 'https://dart.dev/',
  },
];

export default techs;
